<template>
  <div>
    <commond-form
      :request="finalForm"
      :route="`/v1/cuota/update/${cuota.id}`"
      :run="run"
      action="POST"
      @cancel="run = false"
      @success="cuotaUpdated"
      @afterError="run = false"
    >
      <template #data>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="form.baseComision"
              label="Base Comisión"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <!-- <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="cuota.fechaConciliacionAplicada"
              label="Fecha de comisión recibida"
              outlined
              readonly
              hint="Solo lectura"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="cuota.folioDeConciliacion"
              label="Folio de Movimiento Bancario"
              outlined
              readonly
              hint="Solo lectura"
              persistent-hint
            ></v-text-field>
          </v-col> -->
          <v-col
            v-if="
              cuota.statusComision == '1' &&
                cuota.status == 'Pagada' &&
                puedeVerDesglose
            "
            cols="12"
          >
            <v-row>
              <v-col cols="12" md="3">
                <v-checkbox
                  v-model="extraForm.checkConciliacionAplicada"
                  label="Conciliacion aplicada"
                ></v-checkbox>
              </v-col>
              <v-col v-if="extraForm.checkConciliacionAplicada" cols="12" md="3">
                <v-text-field
                  v-model="extraForm.fechaConciliacionAplicada"
                  label="Fecha de conciliacion"
                  outlined
                  :rules="[$rules.required]"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col v-if="extraForm.checkConciliacionAplicada" cols="12" md="3">
                <v-text-field
                  v-model="extraForm.folioDeConciliacion"
                  label="Folio de conciliacion"
                  outlined
                  :rules="[$rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="cuota.porcentajeComisionAseguradora"
              label="Comisión pagada por la aseguradora"
              outlined
              readonly
              hint="Solo lectura"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="form.montoComisionPagadaAseguradora"
              label="Monto Comisión Pagada por la Aseguradora"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="form.porcentajeComisionAgente"
              label="Comisión Agente"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="cuota.montoComisionAgente"
              label="Monto de Comisión Agente"
              outlined
              readonly
              hint="Solo lectura"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="cuota.importeAPagarAgente"
              label="Importe a Pagar al Agente"
              outlined
              readonly
              hint="Solo lectura"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="cuota.fechaConciliacionAplicada"
              label="Fecha Pagado Comisión Agente"
              outlined
              readonly
              hint="Solo lectura"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="form.porcentajeComisionSegurify"
              label="Comisión"
              outlined
              :rules="[$rules.required, $rules.numeric]"
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="form.montoComisionSegurify"
              label="Monto Comisión"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="form.porcentajeBono"
              label="Bono"
              outlined
              :rules="[$rules.required, $rules.numeric]"
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="form.baseComision"
              label="Monto Bono"
              outlined
              :rules="[$rules.required]"
              readonly
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="form.folioDeConciliacionBono"
              label="Folio de Movimiento Bancario Bono"
              outlined
              :rules="[$rules.numeric]"
            ></v-text-field>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="3">
            <v-text-field
              v-model="form.montoComisiontotalSeguirfy"
              label="Monto Comisión Total"
              outlined
              :rules="[$rules.numeric]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="
            cuota.statusComision == '2' &&
              (isAgente || isAdmin)
          "
        >
          <!-- <v-col cols="12" md="6">
            <v-text-field
              v-model="extraForm.folioFacturaApagar"
              label="Folio de la Factura por Pagar"
              outlined
              :rules="[$rules.required]"
              :disabled="folioFacturaDisabled"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="
              cuota.statusComision == '2' &&
                !folioFacturaDisabled
            "
            cols="12"
            md="6"
          >
            <v-file-input
              v-model="extraForm.documentosFacturaAgente"
              color="primary"
              counter
              label="Adjunto de Factura del Agente"
              multiple
              prepend-inner-icon="mdi-paperclip"
              :prepend-icon="null"
              :show-size="1000"
              outlined
              :rules="[(v) => !!v.length || 'Es necesario ingresar un adjunto']"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="primary" dark label small>
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-3 mx-2"
                >
                  +{{ documentosFacturaAgente.length - 2 }} Archivo(s)
                </span>
              </template>
            </v-file-input>
          </v-col> -->
          <v-col
            v-if="cuota.statusComision == 2 && puedeVerDesglose"
            cols="12"
            md="3"
          >
            <v-checkbox
              label="Dispersión aplicada"
              v-model="extraForm.checkDispersionAplicada"
            ></v-checkbox>
          </v-col>
          <v-col
            v-if="extraForm.checkDispersionAplicada && puedeVerDesglose"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="extraForm.fechaPagoComisionAgente"
              label="Fecha de Comsión Recibida"
              outlined
              :rules="[$rules.required]"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="extraForm.checkDispersionAplicada && puedeVerDesglose"
            cols="12"
            md="6"
          >
          <v-text-field
              v-model="form.folioPagoAplicadoAsesor"
              label="Folio de pago aplicado al Aseso"
              outlined
              :rules="[$rules.numeric]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('cancel')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm,
  },

  props: {
    cuota: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        baseComision: this.cuota.baseComision,
        montoComisionPagadaAseguradora: this.cuota
          .montoComisionPagadaAseguradora,
        porcentajeComisionAgente: this.cuota.porcentajeComisionAgente,
        porcentajeComisionSegurify: this.cuota.porcentajeComisionSegurify,
        montoComisionSegurify: this.cuota.montoComisionSegurify,
        porcentajeBono: this.cuota.porcentajeBono,
        montoBonoSegurify: this.cuota.montoBonoSegurify,
        folioDeConciliacionBono: this.cuota.folioDeConciliacionBono,
        montoComisiontotalSeguirfy: this.cuota.montoComisiontotalSeguirfy,
      },
      extraForm: {
        checkConciliacionAplicada: this.cuota.comisionRecibida != 0,
        fechaConciliacionAplicada: this.cuota.fechaConciliacionAplicada,
        folioDeConciliacion: this.cuota.folioDeConciliacion,
        folioFacturaApagar: this.cuota.folioFacturaAPagar,
        documentosFacturaAgente: [],
        checkDispersionAplicada: false,
        fechaPagoComisionAgente: null,
        documentosPagoAgente: [],
      },
      run: false,

      folioFacturaDisabled: false,

      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  computed: {
    isAgente() {
      return this.rol == "AGENT";
    },

    isAdmin() {
      return this.rol == "ADMIN";
    },

    puedeVerDesglose() {
      const rolesPermitidos = ["ADMIN", "FINANZAS"];

      return !!rolesPermitidos.find((rol) => rol == this.rol);
    },

    /**
     * Esta computed devuelve los datos que pueden ser mandados
     * de forma opcional, para evitar mandarlos cuando no son necesarios.
     * Así evitamos errores
     */
    extraFormData() {
      let result = {};

      if (
        this.cuota.statusComision == "1" &&
        this.cuota.status == "Pagada"
      ) {
        result = {
          checkConciliacionAplicada: this.extraForm.checkConciliacionAplicada,
          fechaConciliacionAplicada: this.extraForm.fechaConciliacionAplicada,
          folioDeConciliacion: this.extraForm.folioDeConciliacion,
          status: parseInt(this.cuota.statusComision)
        };
      }

      if (this.cuota.statusComision == "2") {
        result = {
          folioFacturaApagar: this.extraForm.folioFacturaApagar,
          checkDispersionAplicada: this.extraForm.checkDispersionAplicada,
          fechaPagoComisionAgente: this.extraForm.fechaPagoComisionAgente,
          folioPagoAplicadoAsesor: this.extraForm.folioPagoAplicadoAsesor,
        };
      }

      return result;
    },

    finalForm() {
      return { ...this.form, ...this.extraFormData };
    },
  },

  mounted() {
    if (this.cuota.folioFacturaAPagar) this.folioFacturaDisabled = true;
  },

  methods: {
    cuotaUpdated() {
      if (this.extraForm.documentosFacturaAgente.length) this.cargarArchivos();
      if (this.extraForm.documentosPagoAgente.length)
        this.cargarArchivosPagoAgente();
      else this.$emit("cuotaUpdated", this.finalForm);
      window.location.reload();
    },

    cargarArchivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      const tipo = "Documento";
      const tipoDocumento = "Otro";
      const nombre = "Adjunto Factura Agente";
      const descripcion = `Adjunto Pago de cuota #${this.cuota.id}`;
      for (let i = 0; i < this.extraForm.documentosFacturaAgente.length; i++) {
        let file = this.extraForm.documentosFacturaAgente[i];
        formData.append("file", file);
      }
      formData.append("nota", descripcion);
      formData.append("tipo", tipo);
      formData.append("tipo_documento", tipoDocumento);
      formData.append("nombre", nombre);
      mainAxios
        .post(`/v1/cuota/documento/upload/${this.cuota.id}`, formData, config)
        .then(
          (result) => {
            this.$emit("cuotaUpdated", this.finalForm);
            console.log(result);
          },
          function(error) {
            console.log(error);
          }
        );
    },

    cargarArchivosPagoAgente() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      const tipo = "Documento";
      const tipoDocumento = "Otro";
      const nombre = "Adjunto de Pago Realizado al Agente";
      const descripcion = `Adjunto Pago al agente`;
      for (let i = 0; i < this.extraForm.documentosPagoAgente.length; i++) {
        let file = this.extraForm.documentosPagoAgente[i];
        formData.append("file", file);
      }
      formData.append("nota", descripcion);
      formData.append("tipo", tipo);
      formData.append("tipo_documento", tipoDocumento);
      formData.append("nombre", nombre);
      mainAxios
        .post(`/v1/cuota/documento/upload/${this.cuota.id}`, formData, config)
        .then(
          (result) => {
            this.$emit("cuotaUpdated", this.finalForm);
            console.log(result);
          },
          function(error) {
            console.log(error);
          }
        );
    },
  },
};
</script>
